@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sectionSecond {
  background: #ee6915;

  & > div {
    padding: 0;
    position: relative;
    margin: 0 auto;
    max-width: 1170px;
    gap: 0;

    @media (min-width: 1228px) {
      padding: 80px 0;
    }
  }
}

:global(#section-4) {
  background-color: #ffffff;
}

.sectionFour {
  background: #f3ff7b;
  margin: 0 40px;
  padding: 20px 0;

  @media (max-width: 768px) {
    margin: 0 24px;
  }

  & header {
    & h2 {
      font-size: 40px;
      color: #000000;
      padding-top: 30px;
      text-align: center;
      justify-self: center;
      word-break: none;

      @media (max-width: 560px) {
        font-size: 30px;
      }
    }

    & p {
      font-size: 20px;
      color: #000000;
      text-align: center;
      line-height: normal;
      margin: 0;
      padding: 0;

      @media (max-width: 560px) {
        font-size: 16px;
      }
    }
  }

  /* padding: 60px 0; */

  /* &>div {
    &:last-child {
      display: flex;
      flex-direction: column;

      &>div {
        &:nth-last-of-type(odd) {
          width: 100%;
          float: left;

          &>div {
            width: 50%;
          }
        }

        &:nth-last-of-type(even) {
          width: 100%;
          float: right;
          display: flex;
          justify-content: flex-end;

          &>div {
            width: 50%;
          }
        }
      }
    }
  } */
}

.sectionFooter {
  background-color: #390a20;

  & a {
    color: #ffffff;
    display: block;
    white-space: pre;
    &:hover {
      color: #ffffff;
    }
  }

  & span {
    color: #ffffff;

    /* &:hover {
      color: #ffffff;
    } */
  }
}

.privacyPage {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 24px 0;
  }

  & > div {
    & header {
      text-align: center;
    }

    & > div {
      & > div {
        & p {
          margin-top: 10px;

          & strong {
            font-size: 18px;
            color: #000000;
          }
        }
      }
    }
  }
}

.faqPage {
  padding: 70px 0;

  @media (max-width: 768px) {
    padding: 24px 0;
  }

  & > div {
    & > div {
      & > div {
        & h2 {
          text-align: center;
          font-size: 40px;
        }

        & p {
          & a {
            color: #0084ff;
          }

          & strong {
            font-size: 24px;
            color: #000000;
          }
        }
      }
    }
  }
}
 
:global(#collaboration-landing) {
  background-color: #fff !important;
}

:global(#old-section){ 
  display: none;
}
.athletePageSection {
  & > div {
    /* @media(min-width:1500px) { */
    gap: 0;
    max-width: 100% !important;
    padding: 53px 24px !important;
    /* } */

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    & > div {
      &:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 0;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        & img {
          /* max-height: 550px; */
          border-radius: 0px;
          object-fit: cover;
        }

        & > div {
          & > div {
            & > div {
              padding-bottom: 47.5% !important;
              @media (min-width: 1023px) {
                height: calc(100vh - 140px);
                padding-bottom: unset !important;
                &>div{
                  position: unset !important;
                  height: 100%;
                }
              }
              @media (max-width: 767px) {
                padding-bottom: 70.5% !important;
              }
            }
          }
        }
      }
    }
  }
  & :global(#athlete-section-block-1),
  & :global(#brand-section-block-1) {
    & > div {
      &:nth-child(2) {
        padding: 76px 75px 0;
        gap: 30px;
        margin: 0;
        @media (max-width: 1200px) {
          padding: 48px 48px 0;
        }
        @media (max-width: 1023px) {
          padding: 48px 16px 0;
        }
      }
    }
    & h3 {
      font-family: "sunset-gothic";
      font-size: 64px;
      font-weight: 700;
      line-height: 77.45px;
      letter-spacing: -0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 0px 0 24px;
      color: #000;
      max-width: 733px;
      @media (max-width: 1200px) {
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 1023px) {
        font-size: 34px;
        line-height: 44px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    & p {
      font-family: "sunset-gothic";
      font-size: 19px;
      font-weight: 400;
      line-height: 28.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      padding: 0;
      margin: 0 0 36px;
      max-width: 733px;
      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  & :global(#athlete-section-block-2),
  & :global(#brand-section-block-2) {
    & h3 {
      font-family: "sunset-gothic";
      font-size: 30px;
      font-weight: 600;
      line-height: 42.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 32px;
      }
      @media (max-width: 580px) {
      max-width: 200px;
      }
    }
    & > div {
      &:nth-child(1) {
        padding: 0px 75px 0;
        gap: 30px;
        margin: 0;
        @media (max-width: 1200px) {
          padding: 0px 48px 0;
        }
        @media (max-width: 1023px) {
          padding: 0px 16px 0;
        }
      }
    }
    & a {
      display: inline-block;
      margin: 12px 0 65px;
      padding: 10px 20px;
      background-color: #f26915;
      color: white;
      border: none;
      border-radius: 70px;
      text-decoration: none;
      cursor: pointer;
      height: 57px;
      width: 197px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: "sunset-gothic";
      font-size: 15px;
      font-weight: 500;
      line-height: 22.5px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      @media (max-width: 767px) {
        height: 50px;
        width: 180px;
        margin: 12px 0 48px;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    & h4 {
      font-family: "sunset-gothic";
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #000;
      padding: 0;
      margin: 0;
      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 22px;
      }
    }

    & p {
      font-family: "sunset-gothic";
      font-size: 15px;
      font-weight: 500;
      line-height: 22.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #000;
      margin: 0 0 48px;
      padding: 0;
      max-width: 842px;
      @media (max-width: 1200px) {
        margin: 0 0 36px;
        font-size: 14px;
        line-height: 20px;
      }
      &:last-child {
        margin-top: 30px;
      }
      &:nth-child(6) {
        margin-bottom: 30px;
      }
    }
  }
}
.athletePage {
  & > div {
    /* @media(min-width:1500px) { */
    max-width: 100% !important;
    padding: 60px 34px !important;
    /* } */

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    & > div {
      &:nth-child(1) {
        display: flex;
        flex-direction: row-reverse;
        gap: 50px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        & img {
          max-height: 550px;
        }

        & > div {
          &:last-child {
            margin-top: 0 !important;
            padding: 0 !important;

            & h2 {
              text-transform: uppercase;
              letter-spacing: normal;
              font-weight: 600;

              @media (max-width: 768px) {
                font-size: 20px;
              }
            }

            & p {
              color: #000000;
              font-weight: 500;
            }

            & a {
              position: relative;
              left: 50%;
              top: 63%;
              border: none;
              background-color: #390a20;
              color: #ffffff;
              width: 200px;
              border-radius: 45px;
              padding: 18px 12px;
              margin-top: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              /* margin: 70px auto 20px 10px; */
              cursor: pointer;
              font-size: 26px;
              margin: 0 auto;

              @media (max-width: 1425px) {
                top: 58%;
              }

              @media (max-width: 1117px) {
                top: 15%;
              }

              @media (max-width: 768px) {
                position: unset;
                margin-top: 20px;
                padding: 10px;
                font-size: 20px;
              }
            }
          }

          & > div {
            & > div {
              padding-bottom: 47.5% !important;
            }
          }
        }
      }

      &:nth-child(2) {
        @media (--viewportMedium) {
          margin-top: 40px;
        }

        & a {
          border: none;
          background-color: #390a20;
          color: #ffffff;
          width: 200px;
          border-radius: 45px;
          padding: 18px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 26px;
          margin: 0 auto;

          @media (max-width: 768px) {
            padding: 10px;
            font-size: 20px;
          }
        }
      }

      &:nth-child(3) {
        /* margin: 100px auto auto auto; */
        margin-top: 70px;
        padding: 45px 100px !important;
        background: #fdd0ff;
        padding: 24px;
        border-radius: 5px;

        @media (max-width: 768px) {
          padding: 24px !important;
          margin-top: 20px;
        }

        & p {
          color: #000000;
          font-weight: 500;
          max-width: 100%;

          & strong {
            font-size: 20px;

            @media (max-width: 768px) {
              font-size: 15px;
            }
          }
        }
      }

      &:nth-child(4) {
        text-align: center;
        margin: 50px auto;
        /* padding: 0 120px; */

        @media (max-width: 1024px) {
          padding: 0;
        }

        & a {
          background-color: #390a20;
          color: #ffffff;
          width: 200px;
          border-radius: 45px;
          padding: 18px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 26px;
          margin: 40px auto;

          @media (max-width: 768px) {
            padding: 10px;
            font-size: 20px;
          }
        }

        & p {
          &:nth-of-type(odd) {
            font-size: 26px;
            font-weight: 600;
            color: #000000;
            line-height: normal;
            max-width: 100%;
          }

          &:nth-of-type(even) {
            margin: 0;
            color: #000000;
            font-size: 17px;
            font-weight: 500;
            line-height: normal;
            max-width: 100%;

            @media (max-width: 768px) {
              padding-top: 12px;
            }

            & > a {
              text-decoration: underline;
              color: #0084ff;
              background-color: transparent !important;
              width: fit-content;
              border-radius: none;
              padding: 0 !important;
              display: inline-block;
              font-size: 17px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.brandPage {
  & > div {
    max-width: 100% !important;
    padding: 60px 34px !important;

    @media (max-width: 768px) {
      padding: 0 24px;
    }

    & > div {
      &:nth-child(1) {
        display: flex;
        flex-direction: row-reverse;
        gap: 50px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        & img {
          max-height: 550px;
        }

        & > div {
          &:last-child {
            margin-top: 0 !important;
            padding: 0 !important;

            & h3 {
              text-transform: uppercase;
              letter-spacing: normal;
              font-weight: 600;

              @media (max-width: 768px) {
                font-size: 20px;
              }
            }

            & p {
              color: #000000;
              font-weight: 500;
            }
          }

          & > div {
            & > div {
              padding-bottom: 64% !important;
            }
          }
        }
      }

      &:nth-child(2) {
        @media (--viewportMedium) {
          margin-top: 40px;
        }

        & a {
          border: none;
          background-color: #390a20;
          color: #ffffff;
          width: 200px;
          border-radius: 45px;
          padding: 18px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 26px;
          margin: 0 auto;

          @media (max-width: 768px) {
            padding: 10px;
            font-size: 20px;
          }
        }
      }

      &:nth-child(3) {
        margin-top: 70px;
        padding: 45px 100px !important;
        background: #f3ff7b;
        padding: 24px;
        border-radius: 5px;

        @media (max-width: 768px) {
          padding: 24px !important;
          margin-top: 20px;
        }

        & p {
          color: #000000;
          font-weight: 500;
          max-width: 100%;

          & strong {
            font-size: 20px;

            @media (max-width: 768px) {
              font-size: 15px;
            }
          }
        }
      }

      &:nth-child(4) {
        text-align: center;
        margin: 50px auto;
        padding: 0 120px;

        @media (max-width: 1024px) {
          padding: 0;
        }

        & a {
          background-color: #390a20;
          color: #ffffff;
          width: 200px;
          border-radius: 45px;
          padding: 18px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          /* margin: 70px auto 20px 10px; */
          cursor: pointer;
          font-size: 26px;
          margin: 40px auto;

          @media (max-width: 768px) {
            padding: 12px;
            font-size: 22px;
          }
        }

        & p {
          &:nth-of-type(odd) {
            font-size: 24px;
            font-weight: 600;
            color: #000000;
            line-height: normal;
            max-width: 100%;

            @media (max-width: 768px) {
              font-size: 20px;
            }
          }

          &:nth-of-type(even) {
            margin: 0;
            color: #000000;
            font-size: 15px;
            font-weight: 500;
            line-height: normal;
            max-width: 100%;

            @media (max-width: 768px) {
              padding-top: 12px;
            }

            & > a {
              text-decoration: underline;
              color: #0084ff;
              background-color: transparent !important;
              width: fit-content;
              border-radius: none;
              padding: 0 !important;
              display: inline-block;
              font-size: 17px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.blogPage {
  & header {
    & h2 {
      padding-top: 70px;
    }
  }

  & > div {
    max-width: 1300px;
    padding-bottom: 60px;

    & h3 {
      font-size: 26px;
      font-weight: 700;
    }

    & p {
      color: #000000;
      font-weight: 500;

      & a {
        color: #0084ff;
        font-weight: 600;
      }
    }
  }
}

.aboutusPage {
  & header {
    & h1 {
      text-align: center;
      max-width: 100%;
      justify-self: unsave;
    }

    & p {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      text-align: center;
    }
  }

  & > div {
    max-width: 1300px;
    padding-bottom: 60px;
    margin: 0 auto;

    & p {
      max-width: 100%;
      color: #000000;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.recommendWrapper {
  padding: 50px 0;

  & > div > div > div > div {
    & a {
      display: inline-block;
      font-size: 15px;
      background-color: #000000;
      color: #fff;
      text-decoration: none;
      box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
      font-weight: 500;
      margin-top: 10px;
      box-shadow: none;
      padding: 6px 12px;
      width: 100px;
      border-radius: 4px;
      text-align: center;

      &:hover {
        background-color: #000000;
        color: #fff;
      }
    }
  }
}

.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;

  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* center */
    margin-left: auto;
    margin-right: auto;
  }

  & > p {
    /* center */
    margin-left: auto;
    margin-right: auto;
  }
}

.alignRight {
  text-align: right;
  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* push to right */
    margin-left: auto;
  }

  & > p {
    /* push to right */
    margin-left: auto;
  }
}

:global(#landing_banner), :global(#section-1) {
  justify-content: unset !important;
  & header {
    max-width: 100%;
    @media (min-width: 1023px) {
      padding: 0 64px;
    }
    & h1 {
      font-family: "sunset-gothic";
      font-size: 90px;
      font-weight: 700;
      line-height: 110px;
      letter-spacing: -0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      max-width: 880px;
      @media (max-width: 1023px) {
        font-size: 60px;
        line-height: 70px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}
:global(#signupbox) {
  justify-content: flex-start;
  & > div {
    background-color: #ee6915 !important;
  }
  & :global(#signupbox-block-1),
  & :global(#signupbox-block-2) {
    border: 1px solid #000000;
    padding: 24px 24px 80px;
    @media (max-width: 1023px) {
      padding: 24px 16px 36px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
  & h3 {
    font-family: "sunset-gothic";
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    margin: 0 0 36px;
    min-height: 100px;
    @media (max-width: 1023px) {
      font-size: 30px;
      list-style: 40px;
    }
  }
  & p {
    font-family: "sunset-gothic";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    width: 66%;
    margin: auto;
    @media (max-width: 1023px) {
      width: 90%;
    }
  }
  & a {
    font-family: "sunset-gothic";
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    display: block;
    background-color: #390a20;
    min-height: 48px;
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 48px;
    width: fit-content;
    margin: 48px auto 0;
    &:hover {
      opacity: 0.9;
    }
  }
}
:global(#collaboration-landing) {
  & .text {
    margin-top: 0;
  }
  & > div {
    & > div {
      gap: 60px;
    }
  }
  & img {
    border-radius: 0;
  }
  & :global(#collaboration-landing-block-1) {
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
  & :global(#collaboration-landing-block-2) {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }
  & h2 {
    text-align: center;
    @media (max-width: 767px) {
      max-width: 240px;
      margin: 0 auto;
    }
  }
  & h3 {
    font-family: "sunset-gothic";
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    @media (max-width: 767px) {
      margin-top: 24px;
    }
  }
  & p,
  & ul li {
    font-family: "sunset-gothic";
    font-size: 18px;
    font-weight: 500;
    line-height: 26.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    width: 70%;
    @media (max-width: 1023px) {
      width: 90%;
      font-size: 16px;
      list-style: 22px;
    }
  }
  & a {
    background-color: #f26915;
    border-radius: 100px;
    font-family: "sunset-gothic";
    font-size: 14px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 8px 48px;
    &:hover {
      opacity: 0.9;
    }
  }
  & header {
    & p {
      text-align: center;
      max-width: 700px;
    }
  }
}
:global(#more-information) {
  background-color: #fff !important;
  & > div {
    padding: 64px 0;
    @media (min-width: 768px) {
      padding: 80px 0 100px;
    }
  }
  & h2 {
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 24px;
  }
  & a {
    background-color: #f26915;
    border-radius: 100px;
    font-family: "sunset-gothic";
    font-size: 14px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 8px 48px;
    &:hover {
      opacity: 0.9;
    }
  }
}
